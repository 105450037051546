import { LastWill } from 'common/types';
import LoadingSpinner from 'generic/components/LoadingSpinner';
import { useSetupMqttDeviceMutation } from 'graphql/types';
import useStore from 'model/store';
import { Suspense, lazy, useEffect, useState } from 'react';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useToast from 'utils/graphql/useToast';
import parseMda3Topic from 'utils/parseMda3Topic';
import TopicCell, {
  getBeaconId,
  isMda2Message,
  isMda2Topic,
} from './components/TopicCell/TopicCell';

interface EnrollDevicesProps {
  shortenedOrganizationUuid: string;
}

const MqttListener = lazy(() => import('generic/components/MqttListener'));

export default function EnrollDevices({
  shortenedOrganizationUuid,
}: EnrollDevicesProps) {
  const enrollmentConfiguration = useStore(
    (state) => state.enrollmentConfiguration,
  );
  const [{ fetching }, setupMqttDevice] = useSetupMqttDeviceMutation();
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [selectedBeacons, setSelectedBeacons] = useState<string[]>([]);
  const [disabledBeacons, setDisabledBeacons] = useState<string[]>([]);
  const [hiddenBeacons, setHiddenBeacons] = useState<string[]>([]);
  const organizationUuid = useStore(
    (state) => state.organizationSettings.organizationUuid,
  );

  // selectedDevices is an array like ["cmd/unconfigured/<orga>/<operator>/<mac>/configure"]
  const setupDevice = (
    selectedDevices: string[],
    resetMessages: () => void,
  ) => {
    if (enrollmentConfiguration?.configuration) {
      const beacons = selectedDevices.map(
        (device) => getBeaconId(device) ?? '',
      );
      setSelectedBeacons(beacons);

      const { mqttPassword, mqttUser, onPremises } =
        enrollmentConfiguration.configuration;

      setupMqttDevice(
        {
          Input: {
            topics: selectedDevices,
            ...enrollmentConfiguration.configuration,
            mqttPassword: onPremises ? mqttPassword : undefined,
            mqttUser: onPremises ? mqttUser : undefined,
          },
        },
        hasuraHeader(HasuraPermissions.VIEW_ADMIN, ['GroupMembers']),
      ).then((data) => {
        if (data.error) {
          toast(data, {
            message: {
              type: 'error',
              content: intl.formatMessage({
                id: 'Error configuring devices',
              }),
            },
          });
        } else {
          toast(data, {
            message: {
              type: 'success',
              content: intl.formatMessage({
                id: 'Configured beacons',
              }),
            },
          });
          setHiddenBeacons(beacons);
          setSelectedBeacons((existing) =>
            existing.filter((e) => !selectedBeacons.includes(e)),
          );
          resetMessages();
        }
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hiddenBeacons.length > 0) {
        setHiddenBeacons([]);
      }
    }, 10_000); // Reset the list of hidden beacons after 10s so it will turn up again if something went wrong

    return () => clearTimeout(timer);
  }, [hiddenBeacons]);

  return (
    <div className="flex flex-col max-h-96 overflow-y-auto">
      <Suspense fallback={<LoadingSpinner loading />}>
        <MqttListener
          hideConnectionDetails
          topics={[
            // MDA1
            // Use this topic to listen to the "offline" message in order to hide the beacon
            `unconfigured/${shortenedOrganizationUuid}/+/+`,
            // Subscribe to the unconfigured version in order to show it in the topic
            `unconfigured/${shortenedOrganizationUuid}/+/+/info/version/+`,

            // MDA2
            `mda2/${organizationUuid}/meta/+`,
            `mda2/${organizationUuid}/death/+`,
          ]}
          ignoreTimestamp
          columns={['topic']}
          filterMessages={({ message, topic }) => {
            if (isMda2Topic(topic)) {
              if (topic.includes('/death/')) {
                setHiddenBeacons((existing) => [
                  ...existing,
                  getBeaconId(topic) ?? '',
                ]);

                return false;
              }

              return true;
            }

            if (message === LastWill.OFFLINE) {
              setHiddenBeacons((existing) => [
                ...existing,
                getBeaconId(topic) ?? '',
              ]);
            }

            return (
              (hiddenBeacons.length
                ? hiddenBeacons.every((beaconId) => !topic.includes(beaconId))
                : true) &&
              // Remove any devices that go offline
              message !== LastWill.OFFLINE &&
              // Do not show the version topic
              topic.split('/').length === 7
            );
          }}
          enableRowSelection
          renderTopicCell={({ row }, resetMessages) => (
            <TopicCell
              message={row.original.message}
              topic={row.original.topic}
              fetching={fetching}
              setupDevice={(selected) => setupDevice(selected, resetMessages)}
              selectedBeacons={selectedBeacons}
              setDisabledBeacons={setDisabledBeacons}
            />
          )}
          disabledItems={disabledBeacons}
          renderSelectedAction={(selectedRows, resetMessages) => [
            {
              onClick: () =>
                setupDevice(
                  selectedRows.map((b) =>
                    isMda2Message(b.original.message)
                      ? `mda2/unconfigured/admincmd/${getBeaconId(b.original.topic)}`
                      : `cmd/unconfigured/${parseMda3Topic(
                          b.original.topic,
                        )}/configure`,
                  ),
                  resetMessages,
                ),
              text: intl.formatMessage({
                id: !enrollmentConfiguration
                  ? 'Set enrollment configuration first'
                  : 'Configure selected devices',
              }),
              icon: <HiOutlineWrenchScrewdriver className="size-5" />,
              permission: HasuraPermissions.VIEW_ADMIN,
            },
          ]}
        />
      </Suspense>
    </div>
  );
}
