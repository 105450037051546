import { Action, type Mda3ConfigurationType } from 'common/types';
import Input from 'generic/components/Form/Input';
import Switch from 'generic/components/Form/Switch/Switch';
import Modal from 'generic/components/Modal';
import ModalFooter from 'generic/components/ModalFooter';
import Tooltip from 'generic/components/Tooltip/Tooltip';
import Transition from 'generic/components/Transition';
import useStore from 'model/store';
import { Mda3SensorPolicyConfiguration } from 'pages/StatusView/components/ConfigureBeaconModal/components/Mda3Configuration/Mda3Configuration';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { useEffect, useState } from 'react';
import {
  HiOutlineChatBubbleLeftEllipsis,
  HiOutlineServerStack,
  HiOutlineSquaresPlus,
  HiOutlineUser,
  HiOutlineWifi,
  HiOutlineWrench,
} from 'react-icons/hi2';
import { toast } from 'sonner';

interface MqttConfigurationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function MqttConfigurationModal({
  open,
  setOpen,
}: MqttConfigurationModalProps) {
  const [validConfiguration, setValidConfiguration] = useState(false);
  const [mda3Configuration, setMda3Configuration] = useState(
    new Map<string, Mda3ConfigurationType>(),
  );
  const enrollmentConfiguration = useStore(
    (state) => state.enrollmentConfiguration,
  );
  const organization = useStore(
    (state) => state.organizationSettings.organizationName,
  );
  const uuid = useStore((state) => state.organizationSettings.organizationUuid);
  const setEnrollmentConfiguration = useStore(
    (state) => state.setEnrollmentConfiguration,
  );
  const [organizationUuid, setOrganizationUuid] = useState(
    enrollmentConfiguration?.configuration?.organizationUuid ?? uuid,
  );
  const [onPremises, setOnPremises] = useState(
    enrollmentConfiguration?.configuration?.onPremises ?? false,
  );
  const [offlineEnrollment, setOfflineEnrollment] = useState(
    enrollmentConfiguration?.configuration?.offlineEnrollment ?? false,
  );
  const [ssid, setSsid] = useState(
    enrollmentConfiguration?.configuration?.ssid ?? '',
  );
  const [wifiPassword, setWifiPassword] = useState(
    enrollmentConfiguration?.configuration?.wifiPassword ?? '',
  );
  const [mqttBroker, setMqttBroker] = useState(
    enrollmentConfiguration?.configuration?.mqttBroker ??
      `mqtts://${import.meta.env.VITE_MQTT_BROKER}:${
        import.meta.env.VITE_MQTT_PORT
      }`,
  );
  const [mqttUser, setMqttUser] = useState(
    enrollmentConfiguration?.configuration?.mqttUser ?? '',
  );
  const [mqttPassword, setMqttPassword] = useState(
    enrollmentConfiguration?.configuration?.mqttPassword ?? '',
  );
  const intl = useIntl();

  useEffect(() => {
    if (!onPremises) {
      setOrganizationUuid(uuid);
      setMqttBroker(
        `mqtts://${import.meta.env.VITE_MQTT_BROKER}:${
          import.meta.env.VITE_MQTT_PORT
        }`,
      );
    }
  }, [onPremises, uuid]);

  return (
    <Modal
      action={Action.ADD}
      title={intl.formatMessage({
        id: 'Device configuration',
      })}
      icon={<HiOutlineWrench className="animate-pulse" />}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          dataTestId={{
            proceed: 'configuration-save',
            cancel: 'configuration-cancel',
          }}
          disabled={
            ssid === '' ||
            wifiPassword === '' ||
            mqttBroker === '' ||
            !mda3Configuration ||
            !validConfiguration ||
            (onPremises &&
              (mqttUser === '' ||
                mqttPassword === '' ||
                organizationUuid === ''))
          }
          action={Action.ADD}
          proceed={<FormattedMessage id="Save configuration" />}
          onProceed={() => {
            if (
              organizationUuid &&
              mda3Configuration.get(organization ?? '')?.sensorPolicyId
            ) {
              const enrollmentConfig = {
                ssid,
                wifiPassword,
                mqttBroker,
                mqttUser,
                mqttPassword,
                organizationUuid,
                onPremises,
                offlineEnrollment,
              };

              setEnrollmentConfiguration({
                configuration: {
                  ...enrollmentConfig,
                  sensorPolicyId:
                    mda3Configuration.get(organization ?? '')?.sensorPolicyId ??
                    0,
                },
              });
              setOpen(false);
              toast.success(intl.formatMessage({ id: 'Saved configuration' }));
            }
          }}
          onCancel={() => setOpen(false)}
        />
      }
    >
      <div className="flex flex-col space-y-2 w-full">
        <Input
          required
          type="text"
          data-test-id="ssid-input"
          label={intl.formatMessage({
            id: 'SSID',
          })}
          value={ssid}
          placeholder={intl.formatMessage({ id: 'SSID' })}
          icon={<HiOutlineWifi />}
          onChangeValue={(e) => setSsid(e)}
        />
        <Input
          required
          type="password"
          data-test-id="password-input"
          label={intl.formatMessage({
            id: 'WiFi password',
          })}
          value={wifiPassword}
          placeholder={intl.formatMessage({ id: 'WiFi password' })}
          icon={<HiOutlineSquaresPlus />}
          onChangeValue={(e) => setWifiPassword(e)}
        />
        <Switch
          isEnabled={onPremises}
          onSetEnable={() => setOnPremises(!onPremises)}
          label={<FormattedMessage id="On-premises" />}
        />
        <Transition show={onPremises} className="space-y-2">
          <Input
            required={onPremises}
            type="text"
            label={intl.formatMessage({
              id: 'Topic',
            })}
            maxLength={36}
            value={organizationUuid}
            placeholder={intl.formatMessage({ id: 'Topic' })}
            icon={<HiOutlineChatBubbleLeftEllipsis />}
            onChangeValue={(e) => setOrganizationUuid(e)}
          />
          <Input
            required
            type="text"
            label={intl.formatMessage({
              id: 'MQTT broker',
            })}
            value={mqttBroker}
            placeholder={intl.formatMessage({ id: 'MQTT broker' })}
            icon={<HiOutlineServerStack />}
            onChangeValue={(e) => setMqttBroker(e)}
          />
          <Input
            required={onPremises}
            type="text"
            label={intl.formatMessage({
              id: 'MQTT user',
            })}
            value={mqttUser}
            placeholder={intl.formatMessage({ id: 'MQTT user' })}
            icon={<HiOutlineUser />}
            onChangeValue={(e) => setMqttUser(e)}
          />
          <Input
            required={onPremises}
            type="password"
            label={intl.formatMessage({
              id: 'MQTT password',
            })}
            value={mqttPassword}
            placeholder={intl.formatMessage({ id: 'MQTT password' })}
            icon={<HiOutlineSquaresPlus />}
            onChangeValue={(e) => setMqttPassword(e)}
          />
        </Transition>
        <Mda3SensorPolicyConfiguration
          organization={organization ?? ''}
          setConfiguration={setMda3Configuration}
          setValid={setValidConfiguration}
        />
        <Transition show={!onPremises}>
          <div className="flex flex-col space-y-1">
            <Switch
              data-test-id="offline-enrollment-switch"
              isEnabled={offlineEnrollment}
              onSetEnable={() => setOfflineEnrollment(!offlineEnrollment)}
              label={
                <div className="flex space-x-1 items-center">
                  <div>
                    <FormattedMessage id="Offline enrollment" />
                  </div>
                  <div>
                    <Tooltip>
                      <FormattedMessage id="offline-enrollment-description" />
                    </Tooltip>
                  </div>
                </div>
              }
            />
            <Transition show={offlineEnrollment}>
              <p className="text-red-500 text-xs">
                <FormattedMessage id="offline-enrollment-warning" />
              </p>
            </Transition>
          </div>
        </Transition>
      </div>
    </Modal>
  );
}
